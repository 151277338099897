import React from 'react';
import Layout from 'components/Layout/Layout';
import SEO from 'components/seo';
import CaseStudyComponent from 'components/CaseStudy/CaseStudy';

import { graphql } from 'gatsby';

const CaseStudy = ({data}) => (

	<Layout>
		<SEO title="Case Study" />
		<CaseStudyComponent data={data.contentfulProject} projects={data.allContentfulProject} />
	</Layout>
)

export default CaseStudy;

export const query = graphql`
	query($slug: String!) {
		contentfulProject(slug: { eq: $slug }) {
			slug
			heading
			subHeading
			link
			description {
				json
			}
			techDescription {
				json
			}
			largeMockup {
				fluid {
					...GatsbyContentfulFluid
				}
			}
			photoGrid {
				fluid {
					...GatsbyContentfulFluid
				}
			}
		}
		allContentfulProject(
			filter: { isActive: { eq: true }}
			sort: { fields: [sortOrder], order: ASC } 
		) {
			nodes {
				slug
			}
		}
	}
`
