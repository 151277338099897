import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Container } from 'reactstrap';
import { Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Slider from 'react-slick';

import { FaArrowRight } from 'react-icons/fa';
import { FaArrowLeft } from 'react-icons/fa';
import { FaCaretRight } from 'react-icons/fa';

const CaseStudy = styled.div`
  margin-top: 64px;
  overflow: hidden;
  h1 {
    ${props => props.theme.main.fonts.displayLarge}
    margin-bottom: 0;
  }
  h2 {
    ${props => props.theme.main.fonts.displayLargeG}
  }
`;

const Description = styled(Container)`
  margin-top: 48px;
  padding-bottom: 32px;
  h3 {
    ${props => props.theme.main.fonts.displayLarge}
    margin-bottom: 32px;
  }
  h4 {
    ${props => props.theme.main.fonts.displaySmall}
    margin-bottom: 16px;
  }
  h5 {
    ${props => props.theme.main.fonts.heading}
    margin-bottom: 16px;
  }
  p {
    ${props => props.theme.main.fonts.body}
  }
  a {
    ${props => props.theme.main.fonts.textLink}
    text-decoration: underline;
  }
`;

const PhotoGrid = styled(Slider)`
  margin-left: -10px;
  margin-right: -10px;
  .slick-slide {
    padding: 10px;
  }
`;

const Navigation = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
  a {
    ${props => props.theme.main.fonts.textLink}
    svg {
      vertical-align: text-top;
    }
    ${breakpoint('md')`
      ${props => props.theme.main.fonts.heading}
    `}
  }
`;

export default ({data, projects}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  const index = projects.nodes.findIndex(node => node.slug === data.slug);
  const prevProject =  index === 0 ? null : projects.nodes[index - 1];
  const nextProject = projects.nodes.length - 1 === index ? null : projects.nodes[index + 1];
  return (
    <CaseStudy>
      <Container>
        <h1>{data.heading}</h1>
        <h2>{data.subHeading}</h2>
      </Container>
      <Img fluid={data.largeMockup.fluid} />
      <Description>
        {documentToReactComponents(data.description.json)}
        <a href={data.link} target="_blank" rel="noopener noreferrer">Visit Website <FaCaretRight /></a>
      </Description>
      <PhotoGrid {...settings}>
        {data.photoGrid.map((photo, i) => (
          <Img key={i} fluid={photo.fluid} />
        ))}
      </PhotoGrid>
      <Description>
      {documentToReactComponents(data.techDescription.json)}
      </Description>
      <Navigation>
        { prevProject ? <Link to={`/projects/${prevProject.slug}`}><FaArrowLeft /> Previous Project </Link> : <div /> }
        { nextProject ? <Link to={`/projects/${nextProject.slug}`}>Next Project <FaArrowRight /></Link> : <div /> }
      </Navigation>
    </CaseStudy>)
}